function runFullpage() {
  let leeadsFullpageSlot = 'div-leeadsFullpageAd';
  let leeadsTakeoverAdslot;
  let leeadsTakeoverAdSizes;

  function leeadsAdClosePlaceholder() {
    let leeadsAdElement = document.getElementById(leeadsFullpageSlot);
    if (leeadsAdElement !== null) {
      leeadsAdElement.parentNode.removeChild(leeadsAdElement);
    }
  }

  if (window.innerWidth > 768) {
    leeadsTakeoverAdslot = '/3953516,22422870891/Poddtoppen/helsidadesktop'; // DESKTOP adslot.
    leeadsTakeoverAdSizes = [
      [1, 1],
      [1920, 1080]
    ];
  } else {
    leeadsTakeoverAdslot = '/3953516,22422870891/Poddtoppen/helsidamobil'; // MOBILA adslot.
    leeadsTakeoverAdSizes = [
      [1, 1],
      [1080, 1920]
    ];
  }

  let leeadsbody = document.body;
  let leeadsChild = leeadsbody.firstElementChild;
  let leeadsdiv = document.createElement('div');
  leeadsdiv.id = leeadsFullpageSlot;
  leeadsdiv.style.width = '0px';
  leeadsdiv.style.height = '0px';
  leeadsbody.insertBefore(leeadsdiv, leeadsChild);

  window.googletag = window.googletag || { cmd: [] };

  window.googletag.cmd.push(() => {
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.enableServices();
    window.googletag.pubads().addEventListener('slotRenderEnded', event => {
      if (event.slot.getSlotElementId() !== leeadsFullpageSlot) return;

      if (event.isEmpty == false) {
        console.log(
          'Closing ',
          leeadsFullpageSlot,
          event.slot.getSlotElementId()
        );
        setTimeout(() => {
          leeadsAdClosePlaceholder();
        }, 21000); // Timeout function for when to remove ad (21s)
      }
    });
    let adSlot = window.googletag.defineSlot(
      leeadsTakeoverAdslot,
      leeadsTakeoverAdSizes,
      leeadsFullpageSlot
    );
    adSlot.addService(window.googletag.pubads());

    window.googletag.display(adSlot);

    if (window.googletag.pubads().isInitialLoadDisabled()) {
      window.googletag.pubads().refresh([adSlot]);
    }
  });
}

let checktimes = 0;
let cmptimer = setInterval(() => {
  if (window.__tcfapi !== undefined) {
    clearInterval(cmptimer);
    window.__tcfapi('addEventListener', 2, (tcData, success) => {
      if (success) {
        if (
          tcData.gdprApplies === false ||
          tcData.eventStatus === 'tcloaded' ||
          tcData.eventStatus === 'useractioncomplete'
        ) {
          runFullpage();
        }
      }
    });
  } else if (checktimes++ === 10) {
    clearInterval(cmptimer);
    runFullpage();
  }
}, 300);
